import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(private _http: HttpClient) { }
  sendMessage(body) {
    return this._http.post('https://pizzatalia.es:8094/api/email', body);
  }
  sendMessageRespuesta(body) {
    return this._http.post('https://pizzatalia.es:8094/api/email_respuesta', body);
  }
}
