import { Component, OnInit } from '@angular/core';
import {CookiesService} from '../../servicios/cookies/cookies.service';

@Component({
  selector: 'app-com-toogle-cookies',
  templateUrl: './com-toogle-cookies.component.html',
  styleUrls: ['./com-toogle-cookies.component.scss']
})
export class ComToogleCookiesComponent implements OnInit {
  aceptarPolitica = false;
  constructor( private cookie: CookiesService) { }

  ngOnInit() {
    this.aceptarPolitica = (this.cookie.getCookie('privacidad') === 'si');
  }
  getPolitica() {
    return (this.cookie.getCookie('privacidad') === 'si');
  }
  onChange(value) {
    if (value.checked === true) {
      if (this.cookie.getCookie('privacidad') !== 'si' ) {
        this.cookie.setCookie('privacidad', 'si');
        this.aceptarPolitica = true;
        // console.log('si')
        // o.checked = true;
      }
    } else {
      this.cookie.setCookie('privacidad', 'no');
      this.aceptarPolitica = false;
      this.cookie.clearCookies();
      // console.log('no')
    }
  }
}
