import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, HostListener, Inject} from '@angular/core';
import {MetasService} from '../../servicios/metas/metas.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {DOCUMENT} from '@angular/common';
import { Imagenes } from '../../imagenes';
import {
  getSupportedInputTypes,
  Platform,
  supportsPassiveEventListeners,
  supportsScrollBehavior,
} from '@angular/cdk/platform';
@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss'],
  animations: [
    trigger('fake', [
      state('true' , style({
        opacity: '1',
        visibility: 'visible'

      })),
      state( 'false', style({
        opacity: '0',
        visibility: 'hidden'

      })),
      // transition
      transition('* => *', animate('2.5s ease-in-out')),
    ]),
    trigger('h1', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('3s', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('3s', style({ opacity: 0 }))
      ])
    ]),
  ]
})

export class InicioComponent implements OnInit, AfterViewInit {
  posScroll: number;
  auxBool: boolean;
  auxBoolMapa: boolean;
  imagenBanner: any;
  imagenBanner2: any;
  imagenBanner3: any;
  tamWinOriginal: number;
  tamWinNuevo: number;
  VENTANA: any;
  VENTANA_INICIAL: any;
  VENTANA_WIDTH: any;
  _cabeceraFake: boolean;
  constructor(public platform: Platform, @Inject(DOCUMENT) private document: any, private meta: MetasService, private el: ElementRef) {
    this.meta.updateTitle();
    this.imagenBanner = Imagenes.inicioImg1;
    this.imagenBanner2 = Imagenes.inicioImg2;
    this.imagenBanner3 = Imagenes.inicioImg3;

  }
   ngAfterViewInit() {
    const banner = this.document.getElementById('banner-principal');

    const a = this.document.defaultView.innerHeight + 16;
    this.tamWinOriginal = a;
    this.tamWinNuevo = 0;
    const b = 0;
    const c = a - b;
    banner.style.height = c + 'px';
    this.auxBool = false;
    this.auxBoolMapa = false;
    this.posScroll = 0;
   }
   ngOnInit() {
    this.VENTANA = this.document.defaultView.innerHeight + 15;
    this.VENTANA_WIDTH = this.document.defaultView.innerWidth;
    this.VENTANA_INICIAL = this.VENTANA;
    this.setBanner();
  }
  llamar(){
    this.document.defaultView.location.href = 'tel: 965 211 756';
  }
  getScroll() {
    const div = this.document.getElementById('fake-header');
    if ( this.document.defaultView.pageYOffset >= 1) {
      div.classList.add('traslucido');


    } else {
      div.classList.remove('traslucido');
    }
    this.posScroll = this.document.defaultView.pageYOffset;
  }

  seleccionar() {
    if (this.auxBool === false) {
      this.activar();
    } else {
      this.desactivar();
    }
  }
  activar() {
    const banner = this.document.getElementById('activo');
    banner.classList.add('activo');

    const bannerBck = this.document.getElementById('activo-bck');
    bannerBck.classList.add('activo');
    this.auxBool = true;
  }
  desactivar() {
    const banner = this.document.getElementById('activo');
    banner.classList.remove('activo');

    const bannerBck = this.document.getElementById('activo-bck');
    bannerBck.classList.remove('activo');
    this.auxBool = false;
  }

  @HostListener('window:scroll', ['$event']) onScrollEvent($event) {
    this.getScroll();
  }

  setBanner() {
    this.tamWinNuevo = this.document.defaultView.innerHeight + 15;
    const aux = this.document.defaultView.innerHeight + 15;
    const width = this.document.defaultView.innerWidth;
    //solo si el ancho excede x tamaño quiere decir que ha cambiado de portrait a landscape 
    // en otro caso aunque se vea el teclado no se redimensiona :)
    // si es menor la altura que el ancho, landscape, poner altura igual al ancho
    if (this.platform.ANDROID || this.platform.IOS) {
     if (width > this.VENTANA_WIDTH || width < this.VENTANA_WIDTH ) {
        // si es igual quiere decir que ha vuelto a portrait
        if (aux === this.VENTANA_WIDTH && aux > this.VENTANA_INICIAL) {
          this.VENTANA = this.VENTANA_INICIAL;
         } else {
         // this.VENTANA = width; // altura igual que el ancho
         this.VENTANA = aux;
         }
     } else if (width === this.VENTANA_WIDTH ) { // si es el mismo tamaño que el inicial
        this.VENTANA = this.VENTANA_INICIAL;
     }
    } else {
      this.VENTANA = aux;
    }
    const banner = this.document.getElementById('banner-principal');
    const a = this.VENTANA;
    banner.style.height = a + 'px';
   
  }

  @HostListener('window:resize', ['$event']) onResize($event) {
    this.setBanner();
  }

  scrollDown() {
    const a = this.document.defaultView.innerHeight - 40;
    this.document.defaultView.scroll({ top: a, left: 0, behavior: 'smooth' });
  }
  verMapa() {
    this.document.defaultView.open('https://goo.gl/maps/Sj9aJMwmeYr' , '_blank');
  }
}
