import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-com-estrellas',
  templateUrl: './com-estrellas.component.html',
  styleUrls: ['./com-estrellas.component.scss']
})
export class ComEstrellasComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
