import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
export interface DialogData {
  nombre: string;
  email: string;
  telefono: string;
  asunto: string;
  mensaje: string;
}
@Component({
  selector: 'app-com-modal',
  templateUrl: './com-modal.component.html',
  styleUrls: ['./com-modal.component.scss']
})
export class ComModalComponent implements OnInit {

  constructor( public dialogRef: MatDialogRef<ComModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  ngOnInit() {
  }
  cerrar(): void {
    this.dialogRef.close();
  }
}
