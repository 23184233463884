import { Injectable } from '@angular/core';
import {MatSidenav} from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class RutasService {
  private seccion: string;
  private icono: string;
  private menuLateral: MatSidenav;
  private ruta: string;
  private rutas = [
    {
      url: '/inicio',
      opciones: {
        seccion: 'Inicio',
        ruta : 'inicio',
        icono: ''
      }
    },
    {
      url: '/quienes-somos',
      opciones: {
        seccion: 'Quiénes somos',
        ruta : 'quienes-somos',
        icono: ''
      }
    },
    {
      url: '/carta',
      opciones: {
        seccion: 'Carta',
        ruta : 'carta',
        icono: ''
      }
    },
    {
      url: '/contacto',
      opciones: {
        seccion: 'Contacto',
        ruta : 'contacto',
        icono: ''
      }
    }
    ,
    {
      url: '/privacidad',
      opciones: {
        seccion: 'Política de privacidad',
        ruta : 'privacidad',
        icono: ''
      }
    }
  ];

  constructor() { }

  public buscarRuta(seccion: string) {
    let i = 0;
    for (i = 0; i < this.rutas.length; i++) {
      if (this.rutas[i].url === seccion) {
        this.seccion = this.rutas[i].opciones.seccion;
        this.ruta = this.rutas[i].opciones.ruta;
        this.icono = this.rutas[i].opciones.icono;
      }
    }
  }

  public getRuta(): string {
    return this.ruta;
  }
  public getSeccion(): string {
    return this.seccion;
  }
  public getIcono(): string {
    return this.icono;
  }
  public getMenuLateral(): MatSidenav {
    return this.menuLateral;
  }

  public setSeccion(seccion: string) {
    this.seccion = seccion;
  }
  public setIcono(icono: string ) {
    this.icono = icono;
  }
  public setMenuLateral(menu: MatSidenav) {
    this.menuLateral = menu;
  }

}
