import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SeccionesService {
secciones: any;
  constructor() {
    this.secciones = [
      ['INICIO', 'inicio'],
      ['QUIENES SOMOS' , 'quienes-somos'],
      ['CARTA' , 'carta'],
      ['CONTACTO' , 'contacto'],
      ['PRIVACIDAD' , 'privacidad']
    ];
   }
  getSecciones() {
    return this.secciones;
  }
}
