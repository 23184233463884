import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { InicioComponent } from './secciones/inicio/inicio.component';
import { QuienesSomosComponent } from './secciones/quienes-somos/quienes-somos.component';
import { ContactoComponent } from './secciones/contacto/contacto.component';
import { CartaComponent } from './secciones/carta/carta.component';

import { PrivacidadComponent } from './secciones/privacidad/privacidad.component';


const rutas: Routes = [
  {
    path: '',
    redirectTo: 'inicio',
    pathMatch: 'full'
  },
  {
    path: 'inicio',
    component: InicioComponent
    ,
    data: {
      pagina: 'inicio',
      title: 'Inicio',
      description: '',
      keywords: ''
    },
    pathMatch: 'full'
  },
  {
    path: 'quienes-somos',
    component: QuienesSomosComponent
    ,
    data: {
      pagina: 'quienes-somos',
      title: 'Quiénes somos',
      description: '',
      keywords: ''
    },
    pathMatch: 'full'
  },
  {
    path: 'contacto',
    component: ContactoComponent
    ,
    data: {
      pagina: 'contacto',
      title: 'Formulario de contacto',
      description: '',
      keywords: ''
    },
    pathMatch: 'full'
  },
  {
    path: 'carta',
    component: CartaComponent
    ,
    data: {
      pagina: 'carta',
      title: 'Nuestra carta',
      description: '',
      keywords: ''
    },
    pathMatch: 'full'
  },
  {
    path: 'privacidad',
    component: PrivacidadComponent
    ,
    data: {
      pagina: 'privacidad',
      title: 'Política de privacidad',
      description: 'Política de privacidad',
      keywords: ''
    },
    pathMatch: 'full'
  },
  { path: '**', redirectTo: 'inicio' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(rutas, {scrollPositionRestoration: 'enabled'} )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routedComponents = [
  InicioComponent,
  QuienesSomosComponent,
  ContactoComponent,
  PrivacidadComponent
];
