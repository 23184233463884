import { Component, OnInit, Inject } from '@angular/core';
import {DOCUMENT} from '@angular/common';
import { HoraAperturaService } from '../../servicios/hora-apertura/hora-apertura.service';

@Component({
  selector: 'app-com-acciones',
  templateUrl: './com-acciones.component.html',
  styleUrls: ['./com-acciones.component.scss']
})
export class ComAccionesComponent implements OnInit {
  snackbar: HoraAperturaService;
  constructor(@Inject(DOCUMENT) private document: any, snackbar: HoraAperturaService ) {
    this.snackbar = snackbar;
   }

  ngOnInit() {
  }
  llamar() {
    this.document.defaultView.location.href = 'tel: 966 59 21 90';
  }
  showSnackbarDomicilio() {
    this.snackbar.showHorarioD();
  }
}
