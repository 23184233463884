import { Component, OnInit, HostListener, AfterViewInit } from '@angular/core';
import { MenuLateralService } from '../../servicios/menu-lateral/menu-lateral.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';
import { SeccionesService } from '../../servicios/secciones/secciones.service';
import { HoraAperturaService } from '../../servicios/hora-apertura/hora-apertura.service';
@Component({
  selector: 'app-com-cabecera',
  templateUrl: './com-cabecera.component.html',
  styleUrls: ['./com-cabecera.component.scss'],
  animations: [

    trigger('menu_icon', [
      state('true' , style({
        transform: 'rotate(90deg)',
        color: '#ffffff'

      })),
      state( 'false', style({
        transform: 'rotate(0deg)',
        color: 'rgba(255,255,255,.7)'
      })),
      // transition
      transition('* => *', animate('300ms ease-in-out')),
    ])
  ]
})
export class ComCabeceraComponent implements OnInit {
  menuLateral: MenuLateralService;
  menuIconBool: boolean;


  secciones: any;

  snackbar: HoraAperturaService;
  router: Router;
  constructor(snackbar: HoraAperturaService ,secciones: SeccionesService, router: Router, menuLateral: MenuLateralService) {
    this.router = router;
    this.menuLateral = menuLateral;
    this.menuIconBool = menuLateral.getMenuLateralBoolean();
    this.secciones = secciones.getSecciones();
    this.snackbar = snackbar;
   }

  getSeleccion(s): boolean {
    return this.router.url.includes(s.toLocaleLowerCase());
  }

  tooglLogoBtn() {
    const aux = this.menuLateral.getMenuLateralBoolean();
    if (aux === true) {
      this.menuLateral.setMenuLateralBoolean(false);
    }
  }
  toggle() {
    this.menuLateral.setMenuLateralBoolean(!this.menuLateral.getMenuLateralBoolean());
    this.menuIconBool = !this.menuIconBool;
    const aux = this.menuLateral.getMenuLateralBoolean();

    //oculto el snackbar del horario del fondo
    this.ocultarSnackbar();
  }
  ocultarSnackbar(){
    //oculto el snackbar del horario del fondo
    this.snackbar.setSnackbarDomicilio(false);
    this.snackbar.setSnackbarTienda(false);
  }
  ngOnInit() {
  }


}
