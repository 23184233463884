import { Component, OnInit, HostListener, AfterViewInit, Inject } from '@angular/core';
import {DOCUMENT} from '@angular/common';
@Component({
  selector: 'app-com-boton-llamar',
  templateUrl: './com-boton-llamar.component.html',
  styleUrls: ['./com-boton-llamar.component.scss']
})
export class ComBotonLlamarComponent implements OnInit, AfterViewInit {

  constructor( @Inject(DOCUMENT) private document: any) {}
  llamar() {
    this.document.defaultView.location.href = 'tel: 966 59 21 90';
  }
  getScroll() {
    const div = this.document.getElementById('boton-llamar');
    if (this.document.defaultView.innerWidth < 768) {
      if ( this.document.defaultView.pageYOffset >= 1) {
        div.classList.add('opaco');
      } else {
        div.classList.remove('opaco');
      }
    } else {
       div.classList.remove('opaco');
    }

  }


  ngOnInit() {
  }
  ngAfterViewInit() {
    /*this.posBtnScroll();*/
  }
  @HostListener('window:scroll', ['$event']) onScrollEvent($event) {
    this.getScroll();
  }
  @HostListener('window:resize', ['$event']) onResize($event) {
    // this.posBtnScroll();
    this.getScroll();
  }
}
