import { Component, OnInit } from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {CookiesService} from '../../servicios/cookies/cookies.service';

@Component({
  selector: 'app-com-aviso-cookies',
  templateUrl: './com-aviso-cookies.component.html',
  styleUrls: ['./com-aviso-cookies.component.scss'],
  providers: [],
  animations: [
    trigger('toggle', [
      state('true', style({
        opacity: '1',
       // visibility: 'visible',
        transform: 'translate3d(0,0,0)'
      })),
      state('false', style({
        opacity: '0',
        // visibility: 'hidden',
        transform: 'translate3d(0,100%,0)'
      })),
      transition('false => true', animate('300ms ease-in')),
      transition('true => false', animate('300ms ease-in'))
    ])

  ]
})
export class ComAvisoCookiesComponent implements OnInit {

  seccion: string;
  seleccion: boolean;
  rutaSitio: string;
  activeLink: any;
  constructor(public coockie: CookiesService ) {
    this.seleccion = false;
  }

  setSeleccionTrue() {
    this.seleccion = true;
    this.coockie.setCookie('privacidad', 'si');
  }
  setSeleccionFalse() {
    this.seleccion = false ;
    // eliminar coockies
    this.coockie.setCookie('privacidad', 'no');
    this.coockie.clearCookies();
  }
  ngOnInit() {
    if (this.coockie.getCookie('privacidad') === 'si') {
      this.setSeleccionTrue();
    } else {
      this.setSeleccionFalse();
    }
  }

}
