import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { EmailService } from '../../servicios/email/email.service';
import { CookiesService } from '../../servicios/cookies/cookies.service';
import { ComModalComponent } from '../../componentes/com-modal/com-modal.component';


@Component({
  selector: 'app-com-form-contacto',
  templateUrl: './com-form-contacto.component.html',
  styleUrls: ['./com-form-contacto.component.scss']
})
export class ComFormContactoComponent implements OnInit {
  submitted = false;
  contactForm: FormGroup;
  nombre: any;
  email: any;
  telefono: any;
  asunto: any;
  mensaje: any;
  asuntos = [];
  v_modal: any;
  constructor(private cookie: CookiesService, private fb: FormBuilder,  public dialog: MatDialog, public _EnviarEmail: EmailService) {

    this.asuntos = [
      'Información',
      'Incidencia técnica',
      'Otros'
    ];
  }

  ngOnInit() {
    this.crearFormulario();
  }
  getPolitica() {
    return (this.cookie.getCookie('privacidad') === 'si');
  }
  crearFormulario() {
    this.contactForm = this.fb.group({
      hideRequired: true,
      floatLabel: 'never',
      'nombre': new FormControl('', [Validators.required, Validators.minLength(3)]),
      'email': new FormControl('', [Validators.required, Validators.minLength(3), Validators.email]),
      'telefono': new FormControl('', [Validators.pattern('[0-9]*')]),
      'asunto':  new FormControl('', [Validators.required]),
      'mensaje': new FormControl('', [Validators.required, Validators.minLength(3)])

    });
  }
  //si el envio se ha completado, mostrar el dialogo
  openDialog(formDirectiva): void {
    const dialogRef = this.dialog.open(ComModalComponent, {
      width: 'auto',
      data: {nombre: this.contactForm.controls['nombre'].value, email: this.contactForm.controls['email'].value, telefono: this.contactForm.controls['telefono'].value, asunto: this.contactForm.controls['asunto'].value, mensaje: this.contactForm.controls['mensaje'].value}
    });
    dialogRef.afterClosed().subscribe(result => {
      //limpio el formulario
      this.contactForm.reset();
      formDirectiva.resetForm();//para que no vuelva a comprobar los validadores de los inputs

    });
  }

  onSubmit(form, formDirectiva) {
    if(form.value !== undefined){
      this._EnviarEmail.sendMessage(form.value).subscribe(() => {
        this.openDialog(formDirectiva);
      });
      this._EnviarEmail.sendMessageRespuesta(form.value).subscribe(() => {
        /*this.openDialog(formDirectiva);*/
      });
    }


  }
  private cargarDatosWeb() {
  }

  getErrorMessageNombre() {
    return  this.contactForm.controls['nombre'].hasError('required') ? 'Debes introducir tu nombre' :
      this.contactForm.controls['nombre'].hasError('minlength') ? 'Mínimo 3 caracteres' :
        '';
  }
  getErrorMessageEmail() {
    return  this.contactForm.controls['email'].hasError('required') ? 'Debes introducir tu email' :
      this.contactForm.controls['email'].hasError('email') ? 'Formato de email incorrecto' :
        this.contactForm.controls['email'].hasError('minlength') ? 'Mínimo 3 caracteres' :
          '';
  }
  getErrorMessageTelefono() {
    return this.contactForm.controls['telefono'].hasError('pattern') ? 'Sólo números' : '';
  }
  getErrorMessageAsunto() {
    return  this.contactForm.controls['asunto'].hasError('required') ? 'Debes introducir el asunto' :
      this.contactForm.controls['asunto'].hasError('minlength') ? 'Mínimo 3 caracteres' :
        '';
  }
  getErrorMessageMensaje() {
    return  this.contactForm.controls['mensaje'].hasError('required') ? 'Debes introducir el mensaje' :
      this.contactForm.controls['mensaje'].hasError('minlength') ? 'Mínimo 3 caracteres' :
        '';
  }
}
