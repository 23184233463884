import { Inicio } from './imagenes/inicio';
import { QuienesSomos } from './imagenes/quienes-somos';
import { Carta } from './imagenes/carta';
import { Contacto } from './imagenes/contacto';
import { Privacidad } from './imagenes/privacidad';

export class Imagenes {
    public static inicioImg1 = Inicio.inicioImg1;
    public static inicioImg2 = '/url/...';
    public static inicioImg3 = '/url/...';

    public static quienesSomosImg1 = QuienesSomos.quiensSomosImg1;

    public static cartaImg1 = Carta.cartaImg1;

    public static contactoImg1 = Contacto.contactoImg1;

    public static privacidadImg1 = Contacto.contactoImg1;

}
