import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import { isPlatformServer, isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CookiesService {

  constructor(@Inject(PLATFORM_ID) private platformId: object) {

  }

  getCookie(a) {
    // si esta en el servidor
   /* if (isPlatformServer(this.platformId)) {
      return false;
    }*/
    // si esta en el navegador
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem(a);
    }

  }
  removeItem(a) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem(a);
    }
  }
  setCookie(a, b) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(a, b);
    }
  }
  clearCookies() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.clear();
    }
  }
}
