import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MenuLateralService {
  private menuLateralBoolean: boolean;

  constructor() {
    this.menuLateralBoolean = false;

  }

  public getMenuLateralBoolean(): boolean {
    return this.menuLateralBoolean;
  }


  public setMenuLateralBoolean(menu: boolean) {
    this.menuLateralBoolean = menu;
  }

}
