import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-com-logotipo',
  templateUrl: './com-logotipo.component.html',
  styleUrls: ['./com-logotipo.component.scss']
})
export class ComLogotipoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
