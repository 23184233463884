import { Component, OnInit, HostListener, AfterViewInit, Inject } from '@angular/core';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'app-com-boton-scroll',
  templateUrl: './com-boton-scroll.component.html',
  styleUrls: ['./com-boton-scroll.component.scss']
})
export class ComBotonScrollComponent implements OnInit, AfterViewInit {

  constructor( @Inject(DOCUMENT) private document: any) {}

  getScroll() {
    const div = this.document.getElementById('boton-scroll');
    if (this.document.defaultView.innerWidth < 768) {
      if ( this.document.defaultView.pageYOffset >= 1) {
        div.classList.add('opaco');
      } else {
        div.classList.remove('opaco');
      }
    } else {
       div.classList.remove('opaco');
    }

  }

  subirScroll() {
    this.document.defaultView.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }
  posBtnScroll() {
    const div = this.document.getElementById('boton-scroll');
    const alto = this.document.defaultView.innerHeight - 50;
    let porcentaje = 0.05 * alto;
    porcentaje = alto - porcentaje;
    div.style.top = porcentaje + 'px';
  }
  ngOnInit() {
  }
  ngAfterViewInit() {
    /*this.posBtnScroll();*/
  }
  @HostListener('window:scroll', ['$event']) onScrollEvent($event) {
    this.getScroll();
  }
  @HostListener('window:resize', ['$event']) onResize($event) {
    // this.posBtnScroll();
    this.getScroll();
  }
}
