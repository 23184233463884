import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material';
import {APP_ID, Inject, PLATFORM_ID} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';


/*    SECCIONES    */
import { InicioComponent } from './secciones/inicio/inicio.component';
import { PrivacidadComponent } from './secciones/privacidad/privacidad.component';
import { ContactoComponent } from './secciones/contacto/contacto.component';
import { CartaComponent } from './secciones/carta/carta.component';
import { QuienesSomosComponent } from './secciones/quienes-somos/quienes-somos.component';
/*    COMPONENTES    */
import { ComAvisoCookiesComponent } from './componentes/com-aviso-cookies/com-aviso-cookies.component';
import { ComCabeceraComponent } from './componentes/com-cabecera/com-cabecera.component';
import { ComBotonScrollComponent } from './componentes/com-boton-scroll/com-boton-scroll.component';
import { ComBotonLlamarComponent } from './componentes/com-boton-llamar/com-boton-llamar.component';
import { ComFormContactoComponent } from './componentes/com-form-contacto/com-form-contacto.component';
import { ComMenuLateralComponent } from './componentes/com-menu-lateral/com-menu-lateral.component';
import { ComModalComponent } from './componentes/com-modal/com-modal.component';
import { ComPieComponent } from './componentes/com-pie/com-pie.component';
import { ComToogleCookiesComponent } from './componentes/com-toogle-cookies/com-toogle-cookies.component';
import { ComHorarioComponent } from './componentes/com-horario/com-horario.component';
import { ComAccionesComponent } from './componentes/com-acciones/com-acciones.component';
import { ComLogotipoComponent } from './componentes/com-logotipo/com-logotipo.component';
import { ComEstrellasComponent } from './componentes/com-estrellas/com-estrellas.component';


/*    SERVICIOS    */
import { BannerService } from './servicios/banner/banner.service';
import { CookiesService } from './servicios/cookies/cookies.service';
import { EmailService } from './servicios/email/email.service';
import { MenuLateralService } from './servicios/menu-lateral/menu-lateral.service';
import { MetasService } from './servicios/metas/metas.service';
import { RutasService } from './servicios/rutas/rutas.service';
import { SeccionesService } from './servicios/secciones/secciones.service';
import { TextosService } from './servicios/textos/textos.service';
import { HoraAperturaService } from './servicios/hora-apertura/hora-apertura.service';

@NgModule({
  declarations: [
    AppComponent,

    InicioComponent,
    PrivacidadComponent,
    ContactoComponent,
    CartaComponent,
    QuienesSomosComponent,

    ComAvisoCookiesComponent,
    ComCabeceraComponent,
    ComBotonScrollComponent,
    ComBotonLlamarComponent,
    ComFormContactoComponent,
    ComMenuLateralComponent,
    ComModalComponent,
    ComPieComponent,
    ComToogleCookiesComponent,
    ComHorarioComponent,
    ComAccionesComponent,
    ComLogotipoComponent,
    ComEstrellasComponent

  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'pizzatalia'}),
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    HttpClientModule
  ],
  providers: [
    BannerService,
    CookiesService,
    EmailService,
    MenuLateralService,
    MetasService,
    RutasService,
    SeccionesService,
    TextosService,
    HoraAperturaService
  ],
  bootstrap: [AppComponent],
  entryComponents: [ComModalComponent],
  schemas: [ NO_ERRORS_SCHEMA ]
})
export class AppModule {
  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(APP_ID) private appId: string) {
    const platform = isPlatformBrowser(platformId) ?
      'in the browser' : 'on the server';
  }
}
