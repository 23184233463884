import { Component, OnInit } from '@angular/core';
import { HoraAperturaService } from '../../servicios/hora-apertura/hora-apertura.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { MenuLateralService } from '../../servicios/menu-lateral/menu-lateral.service';
@Component({
  selector: 'app-com-horario',
  templateUrl: './com-horario.component.html',
  styleUrls: ['./com-horario.component.scss'],
  animations: [
    trigger('snackbar', [
      state('true', style({
        opacity: '1',
       // visibility: 'visible',
        transform: 'translate3d(0,0,0)'
      })),
      state('false', style({
        opacity: '0',
        // visibility: 'hidden',
        transform: 'translate3d(0,100%,0)'
      })),
      transition('false => true', animate('300ms ease-in')),
      transition('true => false', animate('300ms ease-in'))
    ])

  ]
})
export class ComHorarioComponent implements OnInit {
  horario: HoraAperturaService;
  menu_lateral: MenuLateralService;
  hT: boolean;
  hD: boolean;
  hora_tienda: string;
  hora_domicilio: string;
  estado_horario_tienda: string;
  estado_horario_domicilio: string;
  estado_tienda = [
    false,
    false,
    false,
  ];
  estado_domicilio = [
    false,
    false,
    false,
  ];
  constructor(horario: HoraAperturaService, menuLat: MenuLateralService) { 
    this.horario = horario;
    this.hT = false;
    this.hD = false;

    this.hora_tienda = this.horario.getHorarioTienda();
    this.hora_domicilio = this.horario.getHorarioDomicilio();
    this.menu_lateral = menuLat;
   }
  getEstadoTienda(n) {
    this.hora_tienda = this.horario.getHorarioTienda();

    if (this.hora_tienda === 'Cerrado en estos momentos'){
      this.estado_tienda[0] = true;
      this.estado_tienda[1] = false;
      this.estado_tienda[2] = false;
    }
    if (this.hora_tienda === 'Abierto en estos momentos'){
      this.estado_tienda[2] = true;
      this.estado_tienda[0] = false;
      this.estado_tienda[1] = false;
    }
    if (this.hora_tienda === 'A punto de cerrar'){
      this.estado_tienda[1] = true;
      this.estado_tienda[0] = false;
      this.estado_tienda[2] = false;
    }
    if (this.hora_tienda === 'A punto de abrir'){
      this.estado_tienda[1] = true;
      this.estado_tienda[0] = false;
      this.estado_tienda[2] = false;
    }
    return this.estado_tienda[n];
  }
  getEstadoDomicilio(n) {
    this.hora_domicilio = this.horario.getHorarioDomicilio();

    if (this.hora_domicilio === 'Servicio a domicilio no disponible en estos momentos'){
      this.estado_domicilio[0] = true;
      this.estado_domicilio[1] = false;
      this.estado_domicilio[2] = false;
    }
    if (this.hora_domicilio === 'Servicio a domicilio disponible en estos momentos'){
      this.estado_domicilio[2] = true;
      this.estado_domicilio[0] = false;
      this.estado_domicilio[1] = false;
    }
    if (this.hora_domicilio === 'Servicio a domicilio a punto de expirar'){
      this.estado_domicilio[1] = true;
      this.estado_domicilio[0] = false;
      this.estado_domicilio[2] = false;
    }
    if (this.hora_domicilio === 'Servicio a domicilio a punto de comenzar'){
      this.estado_domicilio[1] = true;
      this.estado_domicilio[0] = false;
      this.estado_domicilio[2] = false;
    }
    return this.estado_domicilio[n];
  }
  getHorarioTienda(){
    
    return this.horario.getHorarioTienda();
  }
  getHorarioDomicilio(){
    return this.horario.getHorarioDomicilio();
  }
  showHorarioT(){
    //oculto el menu lateral si esta abierto
    this.menu_lateral.setMenuLateralBoolean(false);

    this.horario.setSnackbarDomicilio(false)
    this.hD = this.horario.getSnackbarDomicilio();
    this.hT = !this.horario.getSnackbarTienda();
    return this.horario.showHorarioT();
  }
  showHorarioD(){
    //oculto el menu lateral si esta abierto
    this.menu_lateral.setMenuLateralBoolean(false);
    
    this.horario.setSnackbarTienda(false)
    this.hT = this.horario.getSnackbarTienda();
    this.hD = !this.horario.getSnackbarDomicilio();
    return this.horario.showHorarioD();
  }
  ngOnInit() {
  }

}
