import {Component, OnInit, ViewChild, HostListener} from '@angular/core';
import { MenuLateralService } from '../../servicios/menu-lateral/menu-lateral.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { RutasService } from '../../servicios/rutas/rutas.service';
import { Router } from '@angular/router';
import { SeccionesService } from '../../servicios/secciones/secciones.service';
import { HoraAperturaService } from '../../servicios/hora-apertura/hora-apertura.service';
@Component({
  selector: 'app-com-menu-lateral',
  templateUrl: './com-menu-lateral.component.html',
  styleUrls: ['./com-menu-lateral.component.scss'],
  providers: [],
  animations: [
    trigger('menuLateral', [
      state('true' , style({
        transform: 'translate3d(0%,0,0)',

      })),
      state( 'false', style({
        transform: 'translate3d(0,-100%,0)'

      })),
      // transition
      transition('true => false', animate('300ms ease-in-out')),
      transition('false => true', animate('300ms ease-in-out')),
    ]),
    trigger('menuLateralBackground', [
      state('true' , style({
        opacity: '1',
        visibility: 'visible'

      })),
      state( 'false', style({
        opacity: '0',
        visibility: 'hidden'

      })),
      // transition
      transition('true => false', animate('300ms ease-in-out')),
      transition('false => true', animate('300ms ease-in-out')),
    ]),
    trigger('menuLateralBackgroundSup', [
      state('true' , style({
        opacity: '1',
        visibility: 'visible'
      })),
      state( 'false', style({
        opacity: '0',
        visibility: 'hidden'

      })),
      // transition
      transition('false => true', animate('0ms ease-in-out')),
      transition('true => false', animate('300ms 300ms ease-in-out')),
    ])
  ]
})
export class ComMenuLateralComponent implements OnInit {
  servicio: MenuLateralService;
  rutas: RutasService;
  rutaActiva: boolean;
  router: Router;
  secciones: any;
  fondo;
  menu;
  fake;
  win;
  horario: HoraAperturaService;
  constructor(horario: HoraAperturaService, secciones: SeccionesService, router: Router, menuLateralServicio: MenuLateralService, rutas: RutasService ) {
    this.servicio = menuLateralServicio;
    this.rutas = rutas;
    this.router = router;
    this.secciones = secciones.getSecciones();
    this.horario = horario;
  }
  getSeleccion(s): boolean {
    return this.router.url.includes(s.toLocaleLowerCase());
  }
  toggle(): void {
    this.servicio.setMenuLateralBoolean(!this.servicio.getMenuLateralBoolean());

  }
  getHorarioTienda(){
    return this.horario.getHorarioTienda();
  }
  getHorarioDomicilio(){
    return this.horario.getHorarioDomicilio();
  }
  ngOnInit() {
  }

}
