import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HoraAperturaService {
  horario_domicilio: string;
  horario_tienda: string;
  snackbar_domicilio: boolean;
  snackbar_tienda: boolean;
  horas_tienda = [
    'Cerrado en estos momentos',
    'Abierto en estos momentos',
    'A punto de cerrar',
    'A punto de abrir'
  ];
  horas_domicilio = [
    'Servicio a domicilio no disponible en estos momentos',
    'Servicio a domicilio disponible en estos momentos',
    'Servicio a domicilio a punto de expirar',
    'Servicio a domicilio a punto de comenzar'
  ];
  dias = [
    'domingo',
    'lunes',
    'martes',
    'miercoles',
    'jueves',
    'viernes',
    'sabado'
    
  ];
  constructor() {
    this.horario_domicilio = this.horas_domicilio[0];
    this.horario_tienda = this.horas_tienda[0];

    this.getDateDomicilio();
    this.getDateTienda();
   
    this.bucle();
    this.snackbar_domicilio = false;
    this.snackbar_tienda = false;
  }

  bucle(){
    //compruebo cada minuto
    setTimeout(() =>  {
      this.getDateDomicilio();
      this.getDateTienda();
      this.bucle();
    }, 60000);
  }

  getSnackbarDomicilio(){
    return this.snackbar_domicilio;
  }
  getSnackbarTienda(){
   return this.snackbar_tienda;
  }
  setSnackbarDomicilio(a){
   this.snackbar_domicilio = a;
  }
  setSnackbarTienda(a){
   this.snackbar_tienda = a;
  }
  showHorarioT(){
    this.snackbar_domicilio = false;
    this.snackbar_tienda = !this.snackbar_tienda;   
    return this.snackbar_tienda;
  }
  showHorarioD(){
    this.snackbar_tienda = false;
    this.snackbar_domicilio = !this.snackbar_domicilio;
    return this.snackbar_domicilio;
  }


  getHorarioDomicilio() {
    return this.horario_domicilio;
  }
  getHorarioTienda() {
    return this.horario_tienda;
  }

  getDateTienda() {
    const da = new Date();
    const db = da.getDay();
    const dia = this.dias[db];
    const hora = da.getHours();
    // si el dia es viernes o sabado que la hora de cierre es mayor de las 00:00 le sumo un dia
    if (dia === 'lunes' || dia === 'martes' || dia === 'miercoles' || dia === 'jueves') {
      this.getAlgoritmoTienda(9, 0, 23, 30);
    } else if (dia === 'viernes' || (dia === 'sabado' && hora < 2)) {
      this.getAlgoritmoTienda(9, 0, 1, 30);
    } else if (dia === 'sabado' || (dia === 'domingo' && hora < 2)) {
      this.getAlgoritmoTienda(9, 30, 1, 30);
    } else {
      this.getAlgoritmoTienda(11, 0, 23, 30);
    }


  }
  getAlgoritmoTienda(h1, m1, h2, m2) {
    const h_1 = new Date();

    const dia = h_1.getDate();
    const mes = h_1.getMonth();
    const anyo = h_1.getFullYear();
    const fecha_auxiliar = (mes + 1) + '/' + dia + '/' + anyo;

    const hora_actual = new Date();

    const horario_mañana_1 = new Date(fecha_auxiliar + ' ' + h1 + ':' + m1 );
    const horario_tarde_2 = new Date(fecha_auxiliar + ' '  + h2 + ':' + m2 );

    //si es sabado o domingo compruebo las horas de la noche correspondientes a viernes y sabado madrugada
    if (hora_actual.getHours() >= 0 && hora_actual.getHours() < 2 && (hora_actual.getDay() === 6 || hora_actual.getDay() === 0 )) {
      var difference = 0;
      if(hora_actual < horario_tarde_2){
        difference = horario_tarde_2.getTime() - hora_actual.getTime(); // This will give difference in milliseconds
        var resultInMinutes = Math.round(difference / 60000);
        if (resultInMinutes <= 15) {
          // a punto de cerrar
          this.horario_tienda = this.horas_tienda[2];
  
        } else {
          // esta abierto
          this.horario_tienda = this.horas_tienda[1];
        }
      } else{
        // esta cerrado
        this.horario_tienda = this.horas_tienda[0];

      }
    } else if (hora_actual < horario_mañana_1 && hora_actual.getHours() > 1 ) {
      // a punto de abrir
      var difference = horario_mañana_1.getTime() - hora_actual.getTime(); // This will give difference in milliseconds
      var resultInMinutes = Math.round(difference / 60000);
      if (resultInMinutes <= 15) {
        this.horario_tienda = this.horas_tienda[3];
      }
    } else if (hora_actual >= horario_mañana_1 && hora_actual.getHours() < 24) {
      if (hora_actual.getDay() !== 6 && hora_actual.getDay() !== 0) {
        let aux = 0;
        if(horario_tarde_2.getHours() < 2) {
            aux = 24;
        } else {
            aux = horario_tarde_2.getHours();
        }
        if(hora_actual.getHours() <= aux){
          let difference = 0;
          if(horario_tarde_2.getHours() <= hora_actual.getHours()){
            difference = hora_actual.getTime() - horario_tarde_2.getTime(); // This will give difference in milliseconds
          } else {
            difference = horario_tarde_2.getTime() - hora_actual.getTime(); // This will give difference in milliseconds
          }
          var resultInMinutes = Math.round(difference / 60000);
          if (resultInMinutes <= 15) {
            // a punto de cerrar
            this.horario_tienda = this.horas_tienda[2];
          } else {
            // esta abierto
            this.horario_tienda = this.horas_tienda[1];
          }
        } else {
          // cerrado
          this.horario_tienda = this.horas_tienda[0];
        }
      } else {
         // esta abierto
         this.horario_tienda = this.horas_tienda[1];
      }
    } else {
      // cerrado
      this.horario_tienda = this.horas_tienda[0];
    }
  }

  getDateDomicilio() {
    const da = new Date();
    const db = da.getDay();
    const dia = this.dias[db];
    const hora = da.getHours();
    // si el dia es viernes o sabado que la hora de cierre es mayor de las 00:00 le sumo un dia
    if (dia === 'lunes' || dia === 'martes' || dia === 'miercoles' || dia === 'jueves') {
      this.getAlgoritmoDomicilio(12, 0, 15, 45, 20, 0, 23, 15);
    } else if (dia === 'viernes' || (dia === 'sabado' && hora < 2)) {
      this.getAlgoritmoDomicilio(12, 0, 15, 45, 20, 0, 1, 15);
    } else if (dia === 'sabado' || (dia === 'domingo' && hora < 2)) {
      this.getAlgoritmoDomicilio(12, 0, 15, 45, 20, 0, 1, 15);
    } else {
      this.getAlgoritmoDomicilio(12, 0, 15, 45, 20, 0, 23, 15);
    }

  }
  getAlgoritmoDomicilio(h1, m1, h2, m2, h3, m3, h4, m4) {
    const h_1 = new Date();

    const dia = h_1.getDate();
    const mes = h_1.getMonth();
    const anyo = h_1.getFullYear();
    const fecha_auxiliar = (mes + 1) + '/' + dia + '/' + anyo;

    const hora_actual = new Date();
   //const hora_actual = new Date(2019,4,27,23,0,0);
   

    const horario_mañana_1 = new Date(fecha_auxiliar + ' ' + h1 + ':' + m1 );
    const horario_mañana_2 = new Date(fecha_auxiliar + ' ' + h2 + ':' + m2 );
    const horario_tarde_1 = new Date(fecha_auxiliar + ' ' + h3 + ':' + m3 );
    const horario_tarde_2 = new Date(fecha_auxiliar + ' ' + h4 + ':' + m4 );
    if (hora_actual.getHours() >= 0 && hora_actual.getHours() < 2 && (hora_actual.getDay() === 6 || hora_actual.getDay() === 0 )) {
      var difference = 0;
      if(hora_actual < horario_tarde_2){
        difference = horario_tarde_2.getTime() - hora_actual.getTime(); // This will give difference in milliseconds
        var resultInMinutes = Math.round(difference / 60000);
        if (resultInMinutes <= 15) {
          // a punto de cerrar
          this.horario_domicilio = this.horas_domicilio[2];
  
        } else {
          // esta abierto
          this.horario_domicilio = this.horas_domicilio[1];
        }
      } else{
        // esta cerrado
        this.horario_domicilio = this.horas_domicilio[0];

      }

    } else if (hora_actual < horario_mañana_1 && hora_actual.getHours() > 1 ) {
      // a punto de abrir por la mañana
      var difference = horario_mañana_1.getTime() - hora_actual.getTime(); // This will give difference in milliseconds
      var resultInMinutes = Math.round(difference / 60000);
      if (resultInMinutes <= 15) {
        this.horario_domicilio = this.horas_domicilio[3];
      }
    } else if (hora_actual >= horario_mañana_1 && hora_actual < horario_mañana_2) {
      var difference = horario_mañana_2.getTime() - hora_actual.getTime(); // This will give difference in milliseconds
      var resultInMinutes = Math.round(difference / 60000);
      if (resultInMinutes <= 15) {
        // a punto de cerrar por la mañana
        this.horario_domicilio = this.horas_domicilio[2];
      } else {
        // esta abierto por la mañana
        this.horario_domicilio = this.horas_domicilio[1];
      }
    } else if(hora_actual >= horario_mañana_2 && hora_actual < horario_tarde_1) {
      //a punto de abrir por la tarde
      var difference = horario_tarde_1.getTime() - hora_actual.getTime(); // This will give difference in milliseconds
      var resultInMinutes = Math.round(difference / 60000);
      if (resultInMinutes <= 15) {
        this.horario_domicilio = this.horas_domicilio[3];
      } else {
        // cerrado
        this.horario_domicilio = this.horas_domicilio[0];
      }
    } else if (hora_actual >= horario_tarde_1 && hora_actual.getHours() < 24) {
      //a punto de cerrar por la tarde
      if (hora_actual.getDay() !== 6 && hora_actual.getDay() !== 0) {
        let aux = 0;
        if(horario_tarde_2.getHours() < 2) {
            aux = 24;
        } else {
            aux = horario_tarde_2.getHours();
        }
        if(hora_actual.getHours() <= aux){
          let difference = 0;
          if(horario_tarde_2.getHours() <= hora_actual.getHours()){
            difference = hora_actual.getTime() - horario_tarde_2.getTime(); // This will give difference in milliseconds
          } else {
            difference = horario_tarde_2.getTime() - hora_actual.getTime(); // This will give difference in milliseconds
          }
          var resultInMinutes = Math.round(difference / 60000);
          if (resultInMinutes <= 15) {
            this.horario_domicilio = this.horas_domicilio[2];
          } else {
            // abierto
            this.horario_domicilio = this.horas_domicilio[1];
          }
        } else {
          // cerrado
          this.horario_domicilio = this.horas_domicilio[0];
        }
      
      } else {
        // esta abierto
        this.horario_domicilio = this.horas_domicilio[1];
     }
     
    } else {
      // cerrado
      this.horario_domicilio = this.horas_domicilio[0];
    }
  }
}
