import {Component, OnInit, ViewChild, HostListener} from '@angular/core';
import { MenuLateralService } from '../../servicios/menu-lateral/menu-lateral.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { RutasService } from '../../servicios/rutas/rutas.service';
import { Router } from '@angular/router';
import { SeccionesService } from '../../servicios/secciones/secciones.service';
import { HoraAperturaService } from '../../servicios/hora-apertura/hora-apertura.service';

@Component({
  selector: 'app-com-pie',
  templateUrl: './com-pie.component.html',
  styleUrls: ['./com-pie.component.scss']
})
export class ComPieComponent implements OnInit {
  secciones: any;
  router: Router;
  servicio: MenuLateralService;
  rutas: RutasService;
  rutaActiva: boolean;
  fondo;
  menu;
  fake;
  win;
  horario: HoraAperturaService;
  constructor(horario: HoraAperturaService, secciones: SeccionesService, router: Router, menuLateralServicio: MenuLateralService, rutas: RutasService ) {
    this.servicio = menuLateralServicio;
    this.rutas = rutas;
    this.router = router;
    this.secciones = secciones.getSecciones();
    this.horario = horario;
    this.router = router;
  }

  getSeleccion(s): boolean {
    return this.router.url.includes(s.toLocaleLowerCase());
  }
  getHorarioTienda(){
    return this.horario.getHorarioTienda();
  }
  getHorarioDomicilio(){
    return this.horario.getHorarioDomicilio();
  }

  ngOnInit() {
  }

}


